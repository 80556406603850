import React from "react";
import { useState, useRef, useEffect } from "react";
import englishImg from "../assets/img/EID-adha-2024-en.jpg";
import englishImg2 from "../assets/img/EID-adha-2024-en.jpg";
// import arabicImg from "../assets/img/hajj1-ar.jpg";
import arabicImg from "../assets/img/EID-adha-2024-ar.jpg";
import arabicImg2 from "../assets/img/EID-adha-2024-ar.jpg";
import rectangular from "../assets/img/rectangular.svg";
import square from "../assets/img/square.svg";
import axios from "axios";

import HomeNav from "./Nav";
import Footer from "./Footer";

function Cards() {
  const canvasRef = useRef(null);
  const [name, setName] = useState("");
  const [position, setPosition] = useState("");
  const [size, setSize] = useState(englishImg);
  const [language, setLanguage] = useState(1)

   async function submitForm(e) {
   
   }
  
  useEffect(() => {
    drawCanvas()
  }, [size,name,position,language]);
 

  const handleNameChange = (event) => {
    setName(event.target.value);
    drawCanvas();
  };

  const handlePositionChange = (event) => {
    setPosition(event.target.value);
    drawCanvas();
  };

  const drawCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const img = new Image();
    if(size == englishImg){
      img.src = size;
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        ctx.textAlign = "left";
        ctx.fillStyle = "black";
        ctx.font = "600 70px Avenir";
        ctx.fillText(name, canvas.width - 2150 , canvas.height - 190);
        ctx.font = "400 70px Avenir";
        ctx.fillText(position, canvas.width - 2150, canvas.height - 110);
      };
    }else if(size == englishImg2){
      img.src = size;
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        ctx.textAlign = "center";
        ctx.fillStyle = "white";
        ctx.font = "bold 55px Avenir";
        ctx.fillText(name, canvas.width - 530, canvas.height - 100);
        ctx.font = "45px Avenir";
      ctx.fillText(position, canvas.width - 530, canvas.height - 170);
      }}
      else if(size == arabicImg){
      img.src = size;
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        ctx.textAlign = "right";
        ctx.fillStyle = "black";
        ctx.font = "500 80px qtzaintext";
        ctx.fillText(name, canvas.width - 80, canvas.height - 200);
        ctx.font = "400 80px qtzaintext";
        ctx.fillText(position, canvas.width - 80, canvas.height - 120);
      };
    }
    else if(size == arabicImg2){
      img.src = size;
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        ctx.textAlign = "center";
        ctx.fillStyle = "white";
        ctx.font = "500 65px qtzaintext";
        ctx.fillText(name, canvas.width - 80, canvas.height - 200);
        ctx.font = "400 80px qtzaintext";
        ctx.fillText(position, canvas.width - 80, canvas.height - 120);
      };
    }
   
  };

  const handleDownload = async () => {
    const canvas = canvasRef.current;
    const link = document.createElement("a");
    link.download = "zain-eid-card.png";
    link.href = canvas.toDataURL();
    link.click();

     const webhookUrl =
       "https://hooks.slack.com/services/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";


var dateObj = new Date();
var month = dateObj.getUTCMonth() + 1; //months from 1-12
var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    
    var screen
  if (size == englishImg || size == arabicImg) {
   screen = "Landscape";
  } else {
    screen = "Portrait";
 }
const time = dateObj.toLocaleString("en-US", {
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  hour12: true,
  timeZoneName: "short",
});

   var newdate = day + "-" + month + "-" + year;
     const data = {
       blocks: [
         {
           type: "header",
           text: {
             type: "plain_text",
             text: "💚 Zain Cards - Eid Aladha",
             emoji: true,
           },
         },
         {
           type: "section",
           fields: [
             {
               type: "mrkdwn",
               text: "*Type:*\n Eid 2024",
             },
           ],
         },
         {
           type: "section",
           fields: [
             {
               type: "mrkdwn",
               text: `*Name:*\n${name}`,
             },
             {
               type: "mrkdwn",
               text: `*Position:*\n${position}`,
             },
           ],
         },
         {
           type: "section",
           fields: [
             {
               type: "mrkdwn",
               text: `*When:*\n${newdate}`,
             },
             {
               type: "mrkdwn",
               text: `*Time:*\n${time}`,
             },
           ],
         },
         {
           type: "section",
           fields: [
             {
               type: "mrkdwn",
               text: `*Language:*\n${language == 1 ? "English" : "Arabic"}`,
             },
             {
               type: "mrkdwn",
               text: `*Screen:*\n${screen}`,
             },
           ],
         },
       ],
     };
    
   

     let res = await axios.post(webhookUrl, JSON.stringify(data), {
       withCredentials: false,
       transformRequest: [
         (data) => {
           return data;
         },
       ],
     });

  };

  const handleSelect = (e) => {
    setSize(e.target.value);
    setName("");
    setPosition("")
  }

  const handleSelectLang = (e) => {
    setLanguage(e.target.value);

    if(e.target.value == 1){
      setSize(englishImg2);
     
    }else{
      setSize(arabicImg2);
    }
    
    setName("");
    setPosition("")
    console.log(size)
  }

  return (
    <div dir="rtl">
      <HomeNav />
      <div  className="container page-up ">
      <div className="bg-home">
      {/* <h4 className="text-center">اختر تفاصيل البطاقة</h4>
      <h4 className="text-center">Choose the card details</h4> */}
      </div>
        <div style={{marginTop:"30px"}} className="row ">
          <div className="col-md col-lg mb-4">
              <div className="text-center container align-items-center justify-content-center ">
                {/* <img
                  src={size}
                  className="card-img-top "
                  style={{ borderRadius:"15px"}}
                  alt="Image 1"
                /> */}
              <canvas style={{ borderRadius:"15px", width: size == englishImg2 || size == arabicImg2 ? "100%" : "100%"}} className="card-img-top " ref={canvasRef}  />

              </div>
          </div>
          <div className="col-md col-lg mb-4">
          <div className="row mt-3">
        <div className="col-md col-lg mx-auto">
       
            <div className="card-body">

            
            <label htmlFor="size" className="form-label">
            اختر لغة البطاقة Choose card language
            </label>
            <div className="mb-3 mt-3 ">
            <div  onChange={handleSelectLang} className="d-flex row bg-white border rounded p-3">
            <div className="d-flex col">
            <div >
            
            <span style={{marginLeft:"10px"}} >عربي </span>
            </div>
            <input type="radio" value="2" name="lang" className="radio"/>
             
            </div>
            <div className="d-flex col">
             <span style={{marginLeft:"10px"}} >English</span>
            <input type="radio" value="1" name="lang" className="radio"/> 
           
            </div>
            </div>
            </div>
            

            {/* <label htmlFor="size" className="form-label mt-3">
               اختر حجم البطاقة Choose your card size
            </label>
            <div className="mb-3 mt-3 ">
            <div  onChange={handleSelect} className="d-flex row bg-white border rounded p-3">
            <div className="d-flex col">
            <div >
            <img style={{marginLeft:"10px"}} className="ml-1" src={rectangular} />
           
            </div>
            <input type="radio" value={ language == 1 ? englishImg2 : arabicImg2} name="size" className="radio"/>
             
            </div>
            <div className="d-flex col">
            <img style={{marginLeft:"10px"}}  src={square} />
              <input type="radio" value={language == 1 ? englishImg :arabicImg} name="size" className=" radio"/> 
             
            </div>
            </div>
            </div> */}
            
              <div className="mb-3 mt-3">
                <label htmlFor="name" className="form-label">
              ادخل اسمك Enter name 
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  value={name}
                  onChange={handleNameChange}
                />
              </div>
              <div className=" last-input" >
                <label htmlFor="position" className="form-label">
                أدخل منصبك Enter your position 
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="position"
                  value={position}
                  onChange={handlePositionChange}
                />
              </div>
              {/* <div className="text-center">
                <canvas style={{}} ref={canvasRef} className="img-fluid" />
              </div> */}
            </div>
            <div  id="download-button" className="text-center ">
              <button disabled={name == ""} className="btn btn-primary bb" onClick={handleDownload}>
                قم بالتنزيل Download now 
              </button> 
            </div>
        </div>
      </div>
      </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default Cards;
