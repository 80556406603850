import React from "react";
import { Link } from "react-router-dom";
import HomeNav from "../components/Nav";
import Cards from "../components/Cards";
import homeImg from "../assets/img/home.png";



function Home() {
  return (
    <div>
      <HomeNav />
      <div className="container d-flex flex-column min-vh-100 justify-content-center align-items-center">
        <img src={homeImg} />
        <div className="mt-4">
          <h1 className="title">حياك الله</h1>
          <h1 className="title">Hello</h1>
        </div>

        <div className="mt-4 ">
          <p className="text-center">صمم بطاقة التهنئة الخاصة بك</p>
          <p className="text-center">Design your own greeting card</p>
        </div>
        <div className="mt-4 ">
          <Link to="/cards">
            <button className="btn btn-primary">Start إبدأ</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Home;
