import React from "react";
import HomeNav from "./components/Nav";
import Cards from "./components/Cards"
import Home from "./pages/Home"
import Arabic1 from "./pages/Arabic1";
import Arabic2 from "./pages/Arabic2";
import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";


function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/cards" element={<Cards />} />
          <Route path="/arabic-card-1" element={<Arabic1 />} />
          <Route path="/arabic-card-2" element={<Arabic2 />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
