// import React from "react";
import HomeNav from "../components/Nav";
import Cards from "../components/Cards";

import React, { useState, useRef, useEffect } from "react";
import arabicImg from "../assets/img/arabic-2.jpeg";

function Arabic2() {
  const canvasRef = useRef(null);
  const [name, setName] = useState("");
  const [position, setPosition] = useState("");

  const handleNameChange = (event) => {
    setName(event.target.value);
    drawCanvas();
  };

  const handlePositionChange = (event) => {
    setPosition(event.target.value);
    drawCanvas();
  };

  const drawCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.src = arabicImg;
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      ctx.font = "bold 25px Cairo";
      ctx.textAlign = "center";
      ctx.fillStyle = "white";
      ctx.fillText(name, canvas.width - 240, canvas.height - 500);
      ctx.fillText(position, canvas.width - 240, canvas.height - 470);
    };
  };

  const handleDownload = () => {
    const canvas = canvasRef.current;
    const link = document.createElement("a");
    link.download = "greeting-card.png";
    link.href = canvas.toDataURL();
    link.click();
  };

  return (
    <div className="container">
     <HomeNav />
      <div className="row mt-5">
        <div className="col-md-4 col-lg-6 mx-auto">
          <div className="card">
           
            <div className="card-body">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  الاسم:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  value={name}
                  onChange={handleNameChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="position" className="form-label">
                  Position:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="position"
                  value={position}
                  onChange={handlePositionChange}
                />
              </div>
              <div className="text-center">
                <canvas ref={canvasRef} className="img-fluid" />
              </div>
            </div>
            <div className="card-footer text-center">
              <button className="btn btn-primary" onClick={handleDownload}>
                Download
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


// function Arabic1() {
//   return (
//     <div>
//       <HomeNav />
//       <div className="container w-50 my-4">
//         <div>
//           <img
//             src="assets/img/arabic-1.jpeg"
//             className="card-img-top "
//             alt="Image 1"
//           />
//         </div>
//         <form className="mt-5">
//           <div className="mb-3">
//             <label for="exampleInputEmail1" className="form-label">
//               Your Name
//             </label>
//             <input
//               type="Name"
//               className="form-control"
//               id="exampleInputEmail1"
//               aria-describedby="emailHelp"
//             />
//           </div>
//           <div className="mb-3">
//             <label for="exampleInputEmail1" className="form-label">
//               Your Positon
//             </label>
//             <input
//               type="Name"
//               className="form-control"
//               id="exampleInputEmail1"
//               aria-describedby="emailHelp"
//             />
//           </div>

//           <button type="submit" className="btn btn-primary">
//             Download
//           </button>
//         </form>
//       </div>
//     </div>
//   );
// }

export default Arabic2;
