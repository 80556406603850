import React from "react";
import { Link } from "react-router-dom";
// import logo from "../../public/assets/img/zain-logo.svg";

function HomeNav() {
  return (
    <div>
      <nav className="navbar navbar-expand-lg header ">
        <div className="container-fluid d-flex justify-content-center">
          <Link to="/"><a className="navbar-brand" href="">
            <img src="assets/img/zain-logo.svg" />
          </a>
          </Link>
        </div>
      </nav>
    </div>
  );
}

export default HomeNav;
